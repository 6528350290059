import { AnswerType } from '../types/question-types';

const profiles = [{
    name: 'Challenger',
    lowRange: 15,
    highRange: 25
},{
    name: 'Assembler',
    lowRange: 26,
    highRange: 37
},{
    name: 'Leader',
    lowRange: 38,
    highRange: 49
}, {
    name: 'Visionary',
    lowRange: 50,
    highRange: 60
}];

export const getResults = (answers:AnswerType) => {
    const results =  Object.keys(answers).reduce(function(acumulator: any, key:string) {
        acumulator[answers[key].area] = acumulator[answers[key].area] ? (acumulator[answers[key].area] + answers[key].score) : answers[key].score;
        return acumulator;
    }, {});
    const total:number = Object.keys(results).reduce((acum:number, key:string) => {
        return acum + results[key];
    },0);
    const profile:string = getProfile(total);
    const highRankArea:string = getHighRankArea(results);
    const lowRankArea:string = getLowRankArea(results);
    return {
        areas_scores: results,
        profile,
        profile_handle: profile.toLowerCase(),
        high_rank_area: highRankArea,
        high_rank_area_handle: highRankArea.toLowerCase(),
        low_rank_area: lowRankArea,
        low_rank_area_handle:  lowRankArea.toLowerCase(),
        total
    }
}

export const getProfile = (total:number) => {
    const profile = profiles.find((profile) => {
        return profile.lowRange <= total && profile.highRange >= total;
    });
    return profile.name;
}

export const getHighRankArea = (areas: any) => {
    let max = "";
        
    if (Object.keys(areas).length > 0) {
        Object.keys(areas).forEach(item => {
          if (max === "") {
            max = item;
          } else {
            if (areas[item] > areas[max]) {
              max = item;
            }
          }
        });        
    }
    return max;
}
export const getLowRankArea = (areas: any) => {
    let min = "";
        
    if (Object.keys(areas).length > 0) {
        Object.keys(areas).forEach(item => {
          if (min === "") {
            min = item;
          } else {
            if (areas[item] < areas[min]) {
              min = item;
            }
          }
        });        
    }
    return min;
}


export const nextQuestionIndex = (answers: any) => {
    let index = 1;
    if (answers) {
        Object.keys(answers).find((key, i) => {
            index = i + 1;
            return (`q${index}`!== key);
        });
        index = index + 1;
    }
    return index;
}

export const getContinueLink = (route: string, answers: any, completed: boolean, currentQuestion: number = 0) => {
    if (completed && answers && Object.keys(answers).length === 15) {
        const results = getResults(answers);
        return `${route}result/${results.profile_handle}-${results.high_rank_area_handle}-${results.low_rank_area_handle}`;
    } else {
        const nextIndex = nextQuestionIndex(answers);
        if (currentQuestion && nextIndex > currentQuestion) {
            if (nextIndex === 16) {
                return `${route}final`;
            }
            return `${route}question/${nextIndex}`;
        }
        if (currentQuestion > 0) {
            return `${route}question/${currentQuestion}`;
        } 
        return `${route}question/1`;
    }
}
// Driver code  
export const getCombin = (arr:any) =>
{  
    if (arr.length == 1) { 
        return arr[0]; 
    } else { 
        const ans = []; 
        
        // recur with the rest of the array. 
        const otherCases:any = getCombin(arr.slice(1));  
        for (var i = 0; i < otherCases.length; i++) { 
            for (var j = 0; j < arr[0].length; j++) { 
                ans.push(arr[0][j] + otherCases[i]); 
            } 
        } 
        return ans; 
    } 
} 